import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorPatryk as author } from 'data/authors';
import img from 'img/blog/custom-events/custom-events1.png';
import img1 from 'img/blog/custom-events/custom-events2.png';
import img2 from 'img/blog/custom-events/custom-events3.png';
import Banner from 'components/usability-testing/banner';

const Content = () => {
  return (
    <Post>
      <p>
        We&apos;re excited to announce that we&apos;ve released new feature called{' '}
        <strong>Custom Events</strong>. This feature allows you to track custom actions that your
        users perform along with <strong>custom event</strong> properties.
      </p>
      <img src={img1} alt="Custom Events - filters list" title="Custom Events in filters" />
      <p>
        From now on <a href="/help/how-does-the-timeline-work/">Timeline</a> is enriched with your
        events tailored for your business needs.
      </p>
      <img src={img2} alt="Custom Events in session replay" title="Custom Events in Timeline" />
      <p>
        If you need more information take a look{' '}
        <a href="/help/how-do-the-custom-events-work/">here</a>.
      </p>
      <Banner title="Try Custom Events now" />
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Custom Events',
  url: '/blog/custom-events/',
  description: `Introducing Custom Events - the feature that allow you to track custom actions that your users perform along with custom event properties.`,
  author,
  img,
  imgSocial: img,
  date: '2020-12-14',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
